import { useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { Center } from '@bedrock-layout/center';
import { Columns } from '@bedrock-layout/columns';
import { Stack } from '@bedrock-layout/stack';
import { yupResolver } from '@hookform/resolvers/yup';
import { api, ResponseError } from '@use-gateway/api';
import {
  DoublePrice,
  Input,
  Modal,
  ModalLoadingWrapper,
  ModalProps,
  Message,
  Spiner,
  ModalCongratulationsImg,
  ModalText,
  ControlledInput,
  ControlledSelect,
  ControlledTextarea,
  ErrorMessage,
} from '@use-gateway/components';
import { IconCongratulations } from '@use-gateway/icons';
import {
  CryptoCurrency,
  Currency,
  WithdrawalRequestResponseDto,
  WithdrawalsEvents,
} from '@use-gateway/types';
import { useAvailableCurrencies, useEventBus, useWallet } from '@use-gateway/utils';

const formSchemaLocal = yup.object().shape({
  seed: yup
    .string()
    .required()
    .test('validSeed', 'Seed must be 12 words', (value: string | undefined) => {
      if (!value) return false;
      const words = value.trim().split(' ');
      return words.length === 12;
    }),
  address: yup.string().required(),
});

const formSchemaCrypto = yup.object().shape({
  seed: yup
    .string()
    .required()
    .test('validSeed', 'Seed must be 12 words', (value: string | undefined) => {
      if (!value) return false;
      const words = value.trim().split(' ');
      return words.length === 12;
    }),
});

enum WithdrawalSteps {
  confirm = 'confirm',
  success = 'success',
}

export interface PayWithdrawalRequestModalProps extends Partial<ModalProps> {
  id: string;
  type: 'local' | 'crypto';
}

export function PayWithdrawalRequestModal({ id, type, onClose }: PayWithdrawalRequestModalProps) {
  const { emit } = useEventBus();
  const { wallet, totalLocal } = useWallet();

  const [step, setStep] = useState(WithdrawalSteps.confirm);
  const [pending, setPending] = useState(true);
  const [pendingRate, setPendingRate] = useState(false);
  const [request, setRequest] = useState<WithdrawalRequestResponseDto | null>(null);
  const [amountCrypto, setAmountCrypto] = useState(0);
  const [errorPay, setErrorPay] = useState<ResponseError | null>(null);

  const currencies = useAvailableCurrencies(wallet, {
    mustHaveBalance: true,
  });
  const cryptoCurrency = useMemo<CryptoCurrency>(
    () => Object.keys(currencies)[0] as CryptoCurrency,
    [currencies]
  );
  const noCurrenciesAvailable = useMemo(() => Object.keys(currencies).length === 0, [currencies]);

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
    resolver: type === 'local' ? yupResolver(formSchemaLocal) : yupResolver(formSchemaCrypto),
    defaultValues: {
      seed: '',
      address: '',
      cryptocurrency: cryptoCurrency,
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    setFocus,
  } = form;

  const handlePay = handleSubmit(async (data) => {
    setPending(true);
    setErrorPay(null);

    if (type === 'crypto') {
      await api
        .payWithdrawalRequest(id, {
          seed: data.seed,
        })
        .then(() => {
          emit(WithdrawalsEvents.payWithdrawalRequest);
          setStep(WithdrawalSteps.success);
          setErrorPay(null);
        })
        .catch(setErrorPay);
    }
    if (type === 'local') {
      await api
        .payWithdrawalRequest(id, {
          ...data,
        })
        .then(() => {
          emit(WithdrawalsEvents.payWithdrawalRequest);
          setStep(WithdrawalSteps.success);
          setErrorPay(null);
        })
        .catch(setErrorPay);
    }

    setPending(false);
  });

  const getRequest = async () => {
    setErrorPay(null);

    await api
      .getWithdrawalRequest(id)
      .then((res) => {
        setRequest(res);
        setErrorPay(null);
      })
      .catch(setErrorPay);

    setPending(false);
  };

  const getRate = async (currency1: CryptoCurrency, currency2: Currency) => {
    setPendingRate(true);
    setErrorPay(null);

    await api
      .getRate(currency1, currency2)
      .then((res) => {
        const rate = res.rate[`${currency1}-${currency2}`];
        if (request) setAmountCrypto(request.value.amount / rate);
        setErrorPay(null);
      })
      .catch(setErrorPay);

    setPendingRate(false);
  };

  useEffect(() => {
    if (request && request.value && type === 'local')
      getRate(cryptoCurrency, request.value.currency);
  }, [request, cryptoCurrency]);

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const firstError = Object.keys(errors)[0] as keyof typeof errors;
      setFocus(firstError);
    }
  }, [errors]);

  if (type === 'local') {
    if (totalLocal === 0) {
      return (
        <Modal title="Error" onClose={onClose}>
          <Message variant={'error'}>
            You dont have any money in your wallet. Refill your ballance and try again.
          </Message>
        </Modal>
      );
    }

    if (noCurrenciesAvailable) {
      return (
        <Modal title="Error" onClose={onClose}>
          <Message variant={'error'}>
            You dont have any currencies available to pay this withdrawal request.
          </Message>
        </Modal>
      );
    }

    if (step === WithdrawalSteps.confirm) {
      return (
        <Modal title="Pay withdrawal request" onClose={onClose} onNext={handlePay} nextText="Pay">
          {pending && (
            <ModalLoadingWrapper>
              <Spiner />
            </ModalLoadingWrapper>
          )}
          <FormProvider {...form}>
            <Stack gutter={'lg'}>
              <ControlledTextarea name={'seed'} label={'Enter your seed phrase here:'} />
              <ControlledSelect
                name={'cryptocurrency'}
                label={'Fiat currency'}
                options={currencies}
              />
              <ControlledInput name={'address'} label={'Address'} />
              {pendingRate ? (
                <Center centerChildren>
                  <Spiner />
                </Center>
              ) : (
                <Columns columns={2}>
                  Amount
                  {request && (
                    <DoublePrice
                      primary={request.value}
                      secondary={{ amount: amountCrypto, currency: cryptoCurrency }}
                    />
                  )}
                </Columns>
              )}

              {errorPay && (
                <Message variant={'error'} onClose={() => setErrorPay(null)}>
                  <ErrorMessage error={errorPay.message} />
                </Message>
              )}
            </Stack>
          </FormProvider>
        </Modal>
      );
    }
    if (step === WithdrawalSteps.success) {
      return (
        <Modal title="Pay withdrawal request" onClose={onClose} onNext={onClose} nextText="Close">
          <ModalCongratulationsImg>
            <IconCongratulations />
          </ModalCongratulationsImg>
          <ModalText>
            <p>Your withdrawal is on the way.</p>
            <p>Thank you for being with us!</p>
          </ModalText>
        </Modal>
      );
    }
  }

  if (step === WithdrawalSteps.confirm) {
    return (
      <Modal title="Pay withdrawal request" onClose={onClose} onNext={handlePay} nextText="Pay">
        {pending && (
          <ModalLoadingWrapper>
            <Spiner />
          </ModalLoadingWrapper>
        )}
        <FormProvider {...form}>
          <Stack gutter={'lg'}>
            <ControlledTextarea name={'seed'} label={'Enter your seed phrase here:'} />
            {request && request.address !== null && (
              <Input label={'Address'} defautValue={request.address} copyValue readonly />
            )}
            <Columns columns={2}>
              Amount
              {request && <DoublePrice primary={request.value} />}
            </Columns>

            {errorPay && (
              <Message variant={'error'} onClose={() => setErrorPay(null)}>
                <ErrorMessage error={errorPay.message} />
              </Message>
            )}
          </Stack>
        </FormProvider>
      </Modal>
    );
  }

  return (
    <Modal title="Pay withdrawal request" onClose={onClose} onNext={onClose} nextText="Close">
      <ModalCongratulationsImg>
        <IconCongratulations />
      </ModalCongratulationsImg>
      <ModalText>
        <p>Your withdrawal is on the way.</p>
        <p>Thank you for being with us!</p>
      </ModalText>
    </Modal>
  );
}
