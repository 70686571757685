import { Center } from '@bedrock-layout/center';
import { Modal, ModalProps } from '@use-gateway/components';
import { IconClock } from '@use-gateway/icons';
import { styled } from '@use-gateway/theme';

// ~~ Styles

const StatusSign = styled.div`
  display: flex;
  justify-content: center;
  font-size: 48px;
  color: ${({ theme }) => theme.colors.primary};
`;

// const RefundColumn = styled(Column)`
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;

//   &:nth-child(2n) {
//     justify-content: flex-end;
//   }
// `;

// interface DifferenceColumnProps {
//   negative?: boolean;
// }
// const DifferenceColumn = styled(RefundColumn)<DifferenceColumnProps>(({ theme, negative }) => ({
//   fontWeight: 500,
//   color: negative ? theme.colors.danger[100] : theme.colors.success[100],
//   '&:before': {
//     content: negative ? '"-"' : '"+"',
//   },
// }));

// ~~ Main Component

export interface RefundModalProps extends Partial<ModalProps> {
  id: string;
}
export function RefundModal({ id, onClose }: RefundModalProps) {
  // const { emit } = useEventBus();
  // const [payment, setPayment] = useState<PaymentResponseDto | null>(null);

  // const combinedValue = useMemo(() => {
  //   if (!payment) return null;
  //   return allTransactionsValue(payment.transactions);
  // }, [payment]);

  // const difference = useMemo(() => {
  //   if (!payment || !combinedValue) return null;

  //   return {
  //     currency: payment?.pricing.local?.currency,
  //     amount: combinedValue.local.amount - payment.pricing.local!.amount,
  //   };
  // }, [payment, combinedValue]);

  const handleClose = () => {
    if (onClose) onClose();
  };

  // useEffect(() => {
  //   api.getPayment(id).then(setPayment);
  // }, []);

  return (
    <Modal
      title={'Refund'}
      onClose={handleClose}
      backText={'Cancel'}
      nextText={'Refund'}
      onBack={handleClose}>
      <StatusSign>
        <IconClock />
      </StatusSign>
      <Center centerText style={{ margin: '0 10%' }}>
        <h2>The functionality of refunds is under development.</h2>
      </Center>

      {/* <Stack gutter={'xl'}>
        <p>
          Your customer has made more than one transactions. We&nbsp;recomend reaching out to
          refund.
        </p>
        <Columns columns={2}>
          <RefundColumn>Amount requested</RefundColumn>
          <RefundColumn>{price(payment.pricing.local!)}</RefundColumn>
        </Columns>
        <Columns columns={2}>
          <RefundColumn>Transactions</RefundColumn>
          <RefundColumn>
            <Bage>
              <b>{payment.transactions.length}</b>
            </Bage>
          </RefundColumn>
        </Columns>
        <Columns columns={2}>
          <RefundColumn>Difference from requested price</RefundColumn>
          <DifferenceColumn>{price(difference!)}</DifferenceColumn>
        </Columns>
        <Columns columns={2}>
          <RefundColumn>Total amount transacted</RefundColumn>
          <RefundColumn>
            <DoublePrice primary={combinedValue!.local} secondary={combinedValue!.crypto} />
          </RefundColumn>
        </Columns>
      </Stack> */}
    </Modal>
  );
}
