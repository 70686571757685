import { Stack } from '@bedrock-layout/stack';
import { api } from '@use-gateway/api';
import { Modal, ModalProps } from '@use-gateway/components';
import { WebHookDto, WebhooksEvents } from '@use-gateway/types';
import { useEventBus } from '@use-gateway/utils';

export interface WebhookDeleteProps extends Partial<ModalProps> {
  webhook: WebHookDto;
}
export function WebhookDeleteModal({ webhook, onClose }: WebhookDeleteProps) {
  const { emit } = useEventBus();

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleDelete = async () => {
    await api.deleteWebhook(webhook.id);
    emit(WebhooksEvents.webhookWasDeleted);
    handleClose();
  };

  return (
    <Modal
      title={'Delete a webhook'}
      onClose={onClose}
      onBack={handleClose}
      onNext={handleDelete}
      backText={'Cancel'}
      nextText={'Delete'}>
      <Stack gutter={'lg'}>
        <p style={{ margin: 0 }}>
          Are you sure you want to delete <a>{webhook.url}</a> webhook? This action is irreversibly!
        </p>
      </Stack>
    </Modal>
  );
}
