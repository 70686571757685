import { useState } from 'react';
import { CookieModal, PrivacyPolicyModal, TermsOfServiceModal } from '@use-gateway/components';
import {
  CommonEvents,
  CryptoCurrency,
  DepositsEvents,
  IncomingTransactionDto,
  InvoicesEvents,
  P2PEvents,
  PaymentsEvents,
  TestCoins,
  WebHookDto,
  WebhooksEvents,
  WithdrawalsEvents,
} from '@use-gateway/types';
import { useEventBus } from '@use-gateway/utils';
import { ApproveWithdrawalRequestModal } from './approve-withdrawal-request-modal';
import { CreateDepositModal } from './create-deposit-modal';
import { CreateInvoiceModal } from './create-invoice-modal';
import { CreateP2PModal } from './create-p2p-modal';
import { CreatePaymentModal } from './create-payment-modal';
import { CreateRequestModal } from './create-request-modal';
import { DeclineWithdrawalRequestModal } from './decline-withdrawal-request-modal';
import { MetaDataModal } from './meta-data-modal';
import { MobileCreateModal } from './mobile-create';
import { PayWithdrawalRequestModal } from './pay-withdrawal-request-modal';
import { PaymentMethodsModal } from './payment-methods-modal';
import { RefundModal } from './refund-modal';
import { ResolveModal } from './resolve-modal';
import { WebhookDeleteModal } from './webhook-delete-modal';
import { WebhookTestModal } from './webhook-test-modal';
import { WithdrawalModal } from './withdrawal-modal';

export function Modals() {
  const { subscribe } = useEventBus();

  const [mobileCreate, setMobileCreate] = useState(false);
  const [termOfService, setTermOfService] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [cookiePolicy, setCookiePolicy] = useState(false);
  const [createPaymentModal, setCreatePaymentModal] = useState(false);
  const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
  const [createDepositModal, setCreateDepositModal] = useState(false);
  const [createP2pModal, setCreateP2pModal] = useState(false);
  const [resolveModal, setResolveModal] = useState<{
    id: string;
    type: 'payment' | 'invoice' | 'deposit';
  } | null>(null);
  const [refundModal, setRefundModal] = useState<string | null>(null);
  const [withdrawalModal, setWithdrawalModal] = useState<CryptoCurrency | null>(null);
  const [createRequest, setCreateRequest] = useState(false);
  const [approveRequest, setApproveRequest] = useState<string | null>(null);
  const [declineRequest, setDeclineRequest] = useState<string | null>(null);
  const [payWithdrawalRequestModal, setPayWithdrawalRequestModal] = useState<{
    id: string;
    type: 'crypto' | 'local';
  } | null>(null);
  const [deleteWebhook, setDeleteWebhook] = useState<WebHookDto | null>(null);
  const [testWebhook, setTestWebhook] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<{
    availableCryptocurrencies: Array<CryptoCurrency | TestCoins>;
    includedCryptocurrencies: Array<CryptoCurrency | TestCoins>;
    listCryptocurrencies: Array<CryptoCurrency | TestCoins>;
    onResult: (value: Array<CryptoCurrency | TestCoins>) => void;
    defaultEnable?: boolean;
  } | null>(null);

  const [transacionMetaData, setTransactionMetaData] = useState<IncomingTransactionDto>();

  subscribe(CommonEvents.openMobileCreate, () => setMobileCreate(true));
  subscribe(CommonEvents.openTermsOfService, () => setTermOfService(true));
  subscribe(CommonEvents.openPrivacyPolicy, () => setPrivacyPolicy(true));
  subscribe(CommonEvents.openCookiePolicy, () => setCookiePolicy(true));

  subscribe(PaymentsEvents.openPaymentCreate, () => setMobileCreate(false));
  subscribe(PaymentsEvents.openPaymentCreate, () => setCreatePaymentModal(true));

  subscribe(InvoicesEvents.openInvoiceCreate, () => setMobileCreate(false));
  subscribe(InvoicesEvents.openInvoiceCreate, () => setCreateInvoiceModal(true));

  subscribe(DepositsEvents.openDepositCreate, () => setMobileCreate(false));
  subscribe(DepositsEvents.openDepositCreate, () => setCreateDepositModal(true));

  subscribe(P2PEvents.openP2PCreate, () => setMobileCreate(false));
  subscribe(P2PEvents.openP2PCreate, () => setCreateP2pModal(true));

  subscribe(PaymentsEvents.openPaymentResolve, (id: string) =>
    setResolveModal({ id, type: 'payment' })
  );
  subscribe(InvoicesEvents.openInvoiceResolve, (id: string) =>
    setResolveModal({ id, type: 'invoice' })
  );
  subscribe(DepositsEvents.openDepositResolve, (id: string) =>
    setResolveModal({ id, type: 'deposit' })
  );
  subscribe(PaymentsEvents.openPaymentRefound, setRefundModal);
  subscribe(DepositsEvents.openDepositRefound, setRefundModal);

  subscribe(WithdrawalsEvents.openRequestCreate, () => setCreateRequest(true));
  subscribe(WithdrawalsEvents.openWithdrawal, setWithdrawalModal);
  subscribe(WithdrawalsEvents.openPayWithdrawalRequestLocal, (id: string) =>
    setPayWithdrawalRequestModal({ id, type: 'local' })
  );
  subscribe(WithdrawalsEvents.openRequestApprove, setApproveRequest);
  subscribe(WithdrawalsEvents.openRequestDecline, setDeclineRequest);

  subscribe(WithdrawalsEvents.openPayWithdrawalRequestCrypto, (id: string) =>
    setPayWithdrawalRequestModal({ id, type: 'crypto' })
  );
  subscribe(WebhooksEvents.deleteWebhook, (webhook: WebHookDto) => setDeleteWebhook(webhook));
  subscribe(WebhooksEvents.testWebhook, () => setTestWebhook(true));

  subscribe(
    PaymentsEvents.openPaymentMethods,
    (payload: {
      availableCryptocurrencies: Array<CryptoCurrency | TestCoins>;
      includedCryptocurrencies: Array<CryptoCurrency | TestCoins>;
      listCryptocurrencies: Array<CryptoCurrency | TestCoins>;
      onResult: (value: Array<CryptoCurrency | TestCoins>) => void;
      defaultEnable?: boolean;
    }) => setPaymentMethod(payload)
  );

  subscribe(CommonEvents.openTransactionMetaData, (payload: IncomingTransactionDto) =>
    setTransactionMetaData(payload)
  );

  return (
    <>
      {mobileCreate && <MobileCreateModal onClose={() => setMobileCreate(false)} />}
      {createPaymentModal && <CreatePaymentModal onClose={() => setCreatePaymentModal(false)} />}
      {createInvoiceModal && <CreateInvoiceModal onClose={() => setCreateInvoiceModal(false)} />}
      {createDepositModal && <CreateDepositModal onClose={() => setCreateDepositModal(false)} />}
      {createP2pModal && <CreateP2PModal onClose={() => setCreateP2pModal(false)} />}
      {resolveModal && (
        <ResolveModal
          id={resolveModal.id}
          type={resolveModal.type}
          onClose={() => setResolveModal(null)}
        />
      )}
      {refundModal && <RefundModal id={refundModal} onClose={() => setRefundModal(null)} />}
      {withdrawalModal && (
        <WithdrawalModal currency={withdrawalModal} onClose={() => setWithdrawalModal(null)} />
      )}
      {createRequest && <CreateRequestModal onClose={() => setCreateRequest(false)} />}
      {payWithdrawalRequestModal && (
        <PayWithdrawalRequestModal
          id={payWithdrawalRequestModal.id}
          type={payWithdrawalRequestModal.type}
          onClose={() => setPayWithdrawalRequestModal(null)}
        />
      )}
      {declineRequest && (
        <DeclineWithdrawalRequestModal
          id={declineRequest}
          onClose={() => setDeclineRequest(null)}
        />
      )}
      {approveRequest && (
        <ApproveWithdrawalRequestModal
          id={approveRequest}
          onClose={() => setApproveRequest(null)}
        />
      )}
      {termOfService && <TermsOfServiceModal onClose={() => setTermOfService(false)} />}
      {privacyPolicy && <PrivacyPolicyModal onClose={() => setPrivacyPolicy(false)} />}
      {cookiePolicy && <CookieModal onClose={() => setCookiePolicy(false)} />}
      {deleteWebhook && (
        <WebhookDeleteModal webhook={deleteWebhook} onClose={() => setDeleteWebhook(null)} />
      )}
      {testWebhook && <WebhookTestModal onClose={() => setTestWebhook(false)} />}
      {paymentMethod && (
        <PaymentMethodsModal
          availableCryptocurrencies={paymentMethod.availableCryptocurrencies}
          includedCryptocurrencies={paymentMethod.includedCryptocurrencies}
          listCryptocurrencies={paymentMethod.listCryptocurrencies}
          onResult={paymentMethod.onResult}
          onClose={() => setPaymentMethod(null)}
          defaultEnable={paymentMethod.defaultEnable}
        />
      )}
      {transacionMetaData && (
        <MetaDataModal
          onClose={() => setTransactionMetaData(undefined)}
          transaction={transacionMetaData}
        />
      )}
    </>
  );
}
