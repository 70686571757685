/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAsyncMemo } from 'use-async-memo';
import { Center } from '@bedrock-layout/center';
import { Stack } from '@bedrock-layout/stack';
import { api, ResponseError } from '@use-gateway/api';
import {
  DoublePrice,
  DoublePriceTextWrapper,
  ErrorMessage,
  Input,
  InputCrypto,
  Message,
  Modal,
  ModalCongratulationsImg,
  ModalLoadingWrapper,
  ModalProps,
  ModalText,
  PanelSectionLoadingWrapper,
  Spiner,
  Switch,
  Textarea,
} from '@use-gateway/components';
import { IconCongratulations } from '@use-gateway/icons';
import {
  CryptoCurrency,
  CurrencyNameMap,
  UnknownFunction,
  WithdrawalsEvents,
} from '@use-gateway/types';
import { useAuth, useDebounce, useEventBus, useWallet } from '@use-gateway/utils';

enum WithdrawalSteps {
  amount = 'amount',
  confirm = 'confirm',
  success = 'success',
}

export interface WithdrawalProps extends Partial<ModalProps> {
  currency: CryptoCurrency;
}
export function WithdrawalModal({ currency, onClose }: WithdrawalProps) {
  // TODO: в коде ниже есть дублирование логики, нужно все переписать по нормальному

  const { user } = useAuth();
  const { wallet } = useWallet();
  const { emit } = useEventBus();

  const [step, setStep] = useState(WithdrawalSteps.amount);
  const [pending, setPending] = useState(false);
  const [pendingCalculation, setPendingCalculation] = useState(false);

  const [amount, setAmount] = useState(0);
  const debouncedAmount = useDebounce<number>(amount, 500);

  const [networkFee, setNetworkFee] = useState(0);
  const [networkFeeCurrency, setNetworkFeeCurrency] = useState(currency);
  const [serviceFee, setServiceFee] = useState(0);
  const [isFiat, setIsFiat] = useState(false);
  const [netFeeMultiplier, setNetFeeMultiplier] = useState(1);
  const [isMaxMode, setIsMaxMode] = useState(false);

  const [address, setAddress] = useState('');
  const [seed, setSeed] = useState('');
  const [withdrawalError, setWithdrawalError] = useState('');

  const [errorFees, setErrorFees] = useState<ResponseError | null>(null);
  const [errorRate, setErrorRate] = useState<ResponseError | null>(null);

  const wrapBack = useCallback((cb: UnknownFunction) => {
    return () => {
      setWithdrawalError('');
      setErrorFees(null);
      setErrorRate(null);
      cb();
    };
  }, []);

  const feeInDifferentCurrency = useMemo(
    () => networkFeeCurrency !== currency,
    [networkFeeCurrency, currency]
  );

  const canNotPayFees = useMemo(() => {
    if (!feeInDifferentCurrency) return false;

    return wallet[networkFeeCurrency]!.value < networkFee;
  }, [feeInDifferentCurrency, networkFeeCurrency, networkFee]);

  const networkFeeInFiat = useAsyncMemo(async () => {
    return await api
      .getRate(networkFeeCurrency, user!.local_currency)
      .then((res) => {
        return res.rate[`${networkFeeCurrency}-${user!.local_currency}`] * networkFee;
      })
      .catch(setErrorRate);
  }, [networkFee]) as number;

  const rate = useAsyncMemo(async () => {
    const localCurrency = user!.local_currency;

    setPending(true);
    return await api
      .getRate(localCurrency, currency)
      .then((res) => {
        setTimeout(() => setPending(false));
        return res.rate[`${localCurrency}-${currency}`];
      })
      .catch(setErrorRate);
  }, [currency]) as number;

  const maxAmount = useAsyncMemo(async () => {
    try {
      return await api
        .getMaxCryptoCurrency(
          currency,
          currency === CryptoCurrency.BTC ? netFeeMultiplier : undefined
        )
        .then((res) => res[currency] ?? 0);
    } catch (error) {
      return 0;
    }
  }, [currency, netFeeMultiplier]) as number;

  const getFees = async () => {
    setPendingCalculation(true);
    await api
      .getFees(currency, amount, currency === CryptoCurrency.BTC ? netFeeMultiplier : undefined)
      .then((res) => {
        setNetworkFeeCurrency(res.network_fee.cryptocurrency);
        setNetworkFee(res.network_fee.amount);
        setServiceFee(res.service_fee);
        setErrorFees(null);
      })
      .catch(setErrorFees);
    setPendingCalculation(false);
  };

  const convertCurrency = (value: number) => {
    return value / rate;
  };

  const onChange = (value: number) => {
    if (isFiat) setAmount(value * rate);
    else setAmount(value);
  };

  const toggleIsFiat = () => {
    setIsFiat(!isFiat);
  };

  const handleClose = () => {
    if (onClose) onClose();
    emit(WithdrawalsEvents.withdrawalWasCreated);
  };

  const sendTo = async () => {
    setWithdrawalError('');
    setPending(true);
    await api
      .sendTo({
        seed,
        address,
        cryptocurrency: currency,
        amount,
        net_fee_multiplier: currency === CryptoCurrency.BTC ? netFeeMultiplier : undefined,
      })
      .then(() => setStep(WithdrawalSteps.success))
      .catch((err) => {
        setWithdrawalError(err.message);
      })
      .finally(() => setPending(false));
  };

  useEffect(() => {
    getFees();
  }, [debouncedAmount]);

  useEffect(() => {
    if (!isMaxMode) getFees();
  }, [netFeeMultiplier]);

  if (step === WithdrawalSteps.amount) {
    return (
      <Modal
        title={'Withdraw'}
        onClose={handleClose}
        onBack={wrapBack(handleClose)}
        backText={'Cancel'}
        onNext={() => setStep(WithdrawalSteps.confirm)}
        nextDisabled={amount > maxAmount || amount === 0 || !address || canNotPayFees}
        nextText={'Next'}>
        <>
          <ModalText>
            {user && user.local_currency && (
              <InputCrypto
                cryptoCurrency={currency}
                fiatCurrency={user.local_currency}
                defaultValue={amount}
                onChange={onChange}
                isFiat={isFiat}
                toggleIsFiat={toggleIsFiat}
                max={maxAmount}
                isMaxMode={isMaxMode}
                setIsMaxMode={setIsMaxMode}
                rate={rate}
              />
            )}
          </ModalText>
          <ModalText>
            <Stack gutter={'lg'}>
              <Input
                label={`${CurrencyNameMap[currency]} address`}
                defautValue={address}
                onChange={setAddress}
                multiline={true}
              />

              {currency === CryptoCurrency.BTC && (
                <Switch
                  label={'Processing speed:'}
                  value={netFeeMultiplier}
                  setValue={(value) => {
                    if (typeof value === 'number') setNetFeeMultiplier(value);
                  }}
                  items={[
                    { value: 0.5, title: 'Slow', description: '1hrs + estimate delivery' },
                    { value: 1, title: 'Normal', description: '~30 min estimate delivery' },
                    { value: 2, title: 'Fast', description: '~10 min estimate delivery' },
                  ]}
                />
              )}
            </Stack>
          </ModalText>
          <ModalText>
            {pendingCalculation && (
              <PanelSectionLoadingWrapper>
                <Spiner />
              </PanelSectionLoadingWrapper>
            )}

            <Stack gutter={'lg'}>
              {canNotPayFees && (
                <Message variant={'error'}>
                  You don&lsquo;t have required amount of {networkFeeCurrency.replace('_', ' ')} to
                  pay a&nbsp;network fee!
                </Message>
              )}
              <DoublePriceTextWrapper>
                <p>Network fee</p>
                {
                  <DoublePrice
                    primary={{ amount: networkFee, currency: networkFeeCurrency }}
                    maxPrecision={8}
                    secondary={{
                      amount: networkFeeInFiat || 0,
                      currency: user!.local_currency,
                    }}
                    approximate
                  />
                }
              </DoublePriceTextWrapper>
              <DoublePriceTextWrapper>
                <p>Service fee</p>
                <DoublePrice
                  primary={{ amount: serviceFee, currency }}
                  maxPrecision={8}
                  secondary={{
                    amount: convertCurrency(serviceFee),
                    currency: user!.local_currency,
                  }}
                />
              </DoublePriceTextWrapper>
              <DoublePriceTextWrapper>
                <p>Total estimate proceeds</p>
                <DoublePrice
                  primary={{
                    amount:
                      amount === 0 ? 0 : convertCurrency(amount + serviceFee) + networkFeeInFiat,
                    currency: user!.local_currency,
                  }}
                  size="lg"
                />
              </DoublePriceTextWrapper>
            </Stack>
          </ModalText>
          {errorFees && (
            <>
              <Message variant={'error'} onClose={() => setErrorFees(null)}>
                <ErrorMessage error={errorFees.message} />
              </Message>
            </>
          )}
          {errorRate && (
            <>
              <Message variant={'error'} onClose={() => setErrorRate(null)}>
                <ErrorMessage error={errorRate.message} />
              </Message>
            </>
          )}
        </>
      </Modal>
    );
  }

  if (step === WithdrawalSteps.confirm) {
    return (
      <Modal
        title={'Withdraw confirmation'}
        onClose={handleClose}
        onBack={wrapBack(() => setStep(WithdrawalSteps.amount))}
        backText={'Back'}
        onNext={sendTo}
        nextDisabled={seed.trim().split(' ').length !== 12}
        nextText={'Confirm'}>
        <>
          {pending && (
            <ModalLoadingWrapper>
              <Spiner />
            </ModalLoadingWrapper>
          )}
          <Stack gutter={'lg'}>
            <Textarea label="Enter your seed phrase here:" defaultValue={seed} onChange={setSeed} />
            {withdrawalError && (
              <Center centerText style={{ width: '100%' }}>
                <Message variant={'error'}>{withdrawalError}</Message>
              </Center>
            )}
          </Stack>
        </>
      </Modal>
    );
  }

  if (step === WithdrawalSteps.success) {
    return (
      <Modal
        title={'Successfully'}
        onClose={handleClose}
        onNext={handleClose}
        backVisible={false}
        nextText={'Wallet'}>
        <>
          <ModalCongratulationsImg>
            <IconCongratulations />
          </ModalCongratulationsImg>
          <ModalText>
            <p>Your withdrawal is on the way.</p>
            <p>Thank you for being with us!</p>
          </ModalText>
        </>
      </Modal>
    );
  }

  return null;
}
