import { useState } from 'react';
import { Stack } from '@bedrock-layout/stack';
import { api, ResponseError } from '@use-gateway/api';
import {
  Modal,
  ModalLoadingWrapper,
  ModalProps,
  Message,
  Spiner,
  Textarea,
  ModalCongratulationsImg,
  ModalText,
  ErrorMessage,
} from '@use-gateway/components';
import { IconCongratulations } from '@use-gateway/icons';
import { WithdrawalsEvents } from '@use-gateway/types';
import { useEventBus } from '@use-gateway/utils';

enum WithdrawalSteps {
  confirm = 'confirm',
  success = 'success',
}

export interface ApproveWithdrawalRequestModalProps extends Partial<ModalProps> {
  id: string;
}

export function ApproveWithdrawalRequestModal({ id, onClose }: ApproveWithdrawalRequestModalProps) {
  const { emit } = useEventBus();
  const [step, setStep] = useState(WithdrawalSteps.confirm);
  const [comment, setComment] = useState('');
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<ResponseError | null>(null);

  const handlePay = async () => {
    setPending(true);
    await api
      .withdrawalRequestApprove(id, comment)
      .then(() => {
        emit(WithdrawalsEvents.withdrawalRequestApproved);
        setStep(WithdrawalSteps.success);
        setError(null);
      })
      .catch(setError)
      .finally(() => setPending(false));
  };

  if (step === WithdrawalSteps.confirm) {
    return (
      <Modal
        title="Approve withdrawal request"
        onClose={onClose}
        onNext={handlePay}
        nextText="Confirm">
        {pending && (
          <ModalLoadingWrapper>
            <Spiner />
          </ModalLoadingWrapper>
        )}
        <Stack gutter={'lg'}>
          <Textarea label="Enter your commentary" onChange={setComment} />
        </Stack>
        {error && (
          <>
            <br />
            <Message variant={'error'} onClose={() => setError(null)}>
              <ErrorMessage error={error.message} />
            </Message>
          </>
        )}
      </Modal>
    );
  }

  return (
    <Modal title="Approve withdrawal request" onClose={onClose} onNext={onClose} nextText="Close">
      <ModalCongratulationsImg>
        <IconCongratulations />
      </ModalCongratulationsImg>
      <ModalText>
        <p>Request was approved!</p>
      </ModalText>
    </Modal>
  );
}
