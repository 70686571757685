import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { Stack } from '@bedrock-layout/stack';
import { yupResolver } from '@hookform/resolvers/yup';
import { api, ResponseError } from '@use-gateway/api';
import {
  Modal,
  ModalProps,
  ControlledInput,
  Input,
  Spiner,
  ModalLoadingWrapper,
  Message,
  ErrorMessage,
} from '@use-gateway/components';
import { P2PEvents } from '@use-gateway/types';
import { useEventBus } from '@use-gateway/utils';

const validationSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    description: yup.string(),
    user_id: yup.string().required('User ID is a required field'),
    site: yup.string().required(),
  })
  .required();

export type CreateP2PProps = Partial<ModalProps>;
export function CreateP2PModal({ onClose }: CreateP2PProps) {
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      description: '',
      user_id: '',
      site: '',
    },
  });
  const { handleSubmit } = form;
  const { emit } = useEventBus();

  const [P2PUrl, setP2PUrl] = useState<string | null>(null);
  const [pending, setPending] = useState(false);

  const [errorCreateP2P, setErrorCreateP2P] = useState<ResponseError | null>(null);
  const createP2P = handleSubmit(async ({ name, description, user_id, site }) => {
    setPending(true);

    await api
      .createP2P({
        name,
        description,
        user_id,
        site,
        metadata: {
          'Created using the Panel': true,
        },
      })
      .then((P2P) => {
        emit(P2PEvents.P2PWasCreated, P2P);
        setP2PUrl(P2P.hosted_url);
        setErrorCreateP2P(null);
      })
      .catch(setErrorCreateP2P);

    setPending(false);
  });

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleNext = async () => {
    if (!P2PUrl) await createP2P();
    else handleClose();
  };

  return (
    <Modal
      title={!P2PUrl ? 'Create new P2P' : 'Copy P2P URL'}
      onClose={onClose}
      backVisible={!P2PUrl}
      onNext={handleNext}
      nextText={!P2PUrl ? 'Create' : 'Close'}>
      {pending && (
        <ModalLoadingWrapper>
          <Spiner />
        </ModalLoadingWrapper>
      )}
      {!P2PUrl ? (
        <FormProvider {...form}>
          <form onSubmit={createP2P}>
            <Stack gutter={'lg'}>
              <ControlledInput name={'name'} label={'P2P name'} />
              <ControlledInput name={'description'} label={'P2P description'} />
              <div />
              <ControlledInput name={'user_id'} label={'User ID'} />
              <ControlledInput name={'site'} label={'Site'} />
            </Stack>
          </form>
        </FormProvider>
      ) : (
        <Stack gutter={'lg'}>
          <Input label={'P2P URL'} defautValue={P2PUrl} copyValue readonly externalLink={P2PUrl} />
        </Stack>
      )}
      {errorCreateP2P && (
        <>
          <br />
          <Message variant={'error'} onClose={() => setErrorCreateP2P(null)}>
            <ErrorMessage error={errorCreateP2P.message} />
          </Message>
        </>
      )}
    </Modal>
  );
}
