import { Currency, PricingItem, TransactionItem } from '@use-gateway/types';

export function allTransactionsValue(transactions: Array<TransactionItem>): {
  local: PricingItem;
  crypto?: PricingItem;
} {
  let localAmount = 0;
  let cryptoAmount = 0;
  let lastCurrency: Currency;
  let isAllSameCurrency = true;

  transactions.forEach((transaction) => {
    const currency = transaction.value.crypto.currency;

    if (lastCurrency && lastCurrency !== currency) {
      isAllSameCurrency = false;
    }

    localAmount = localAmount + transaction.value.local.amount;
    cryptoAmount = cryptoAmount + transaction.value.crypto.amount;
    lastCurrency = currency;
  });

  return {
    local: {
      currency: transactions[0].value.local.currency,
      amount: localAmount,
    },
    ...(isAllSameCurrency
      ? {
          crypto: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            currency: lastCurrency!,
            amount: cryptoAmount,
          },
        }
      : {}),
  };
}
