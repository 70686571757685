import styled from 'styled-components';
import { Stack } from '@bedrock-layout/stack';
import { Modal, ModalProps } from '@use-gateway/components';
import { IconDeposit, IconInvoice, IconPayments } from '@use-gateway/icons';
import { DepositsEvents, InvoicesEvents, PaymentsEvents } from '@use-gateway/types';
import { useEventBus } from '@use-gateway/utils';

const ButtonTransaction = styled.button`
  display: flex;
  align-items: center;
  padding: 8px 36px;
  border: none;
  background-color: transparent;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.dark[100]};
  cursor: pointer;

  svg {
    min-width: 24px;
    margin-right: 16px;
    font-size: 24px;
  }
`;

export type MobileCreateProps = Partial<ModalProps>;
export function MobileCreateModal({ onClose }: MobileCreateProps) {
  const { emit } = useEventBus();

  return (
    <Modal title={'Create'} onClose={onClose}>
      <Stack gutter={'lgXl'}>
        <ButtonTransaction onClick={() => emit(PaymentsEvents.openPaymentCreate)}>
          <IconPayments />
          Payment
        </ButtonTransaction>
        <ButtonTransaction onClick={() => emit(DepositsEvents.openDepositCreate)}>
          <IconDeposit />
          Deposit
        </ButtonTransaction>
        <ButtonTransaction onClick={() => emit(InvoicesEvents.openInvoiceCreate)}>
          <IconInvoice />
          Invoice
        </ButtonTransaction>
      </Stack>
    </Modal>
  );
}
