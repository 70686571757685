export function arrayToggle<T>(array: Array<T>, item: T) {
  const included = array.includes(item);

  if (included) {
    const index = array.indexOf(item);
    array.splice(index, 1);
  } else {
    array.push(item);
  }

  return Array.from(array);
}
