import { useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { random } from 'lodash-es';
import { appWithTranslation } from 'next-i18next';
import { ToastContainer } from 'react-toast';
import { Partytown } from '@builder.io/partytown/react';
import * as Sentry from '@sentry/nextjs';
import { api } from '@use-gateway/api';
import { ErrorBoundary, FullScreenSpiner, ModalRoot } from '@use-gateway/components';
import { Breakpoints, GatewayThemeProvider, useBreakpoints } from '@use-gateway/theme';
import { RatesDto, UserDto, WalletDto } from '@use-gateway/types';
import {
  AuthProvider,
  partytownResolver,
  WalletProvider,
  checkPageDependencies,
  technicalWorksRedirect,
  isItTechnicalWorksPage,
  NoticeableProvider,
} from '@use-gateway/utils';
import { Modals } from 'components/modals';

import 'normalize.css/normalize.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';

const { publicRuntimeConfig } = getConfig();
const { RUNTIME_ENV, GOOGLE_TAG, PROJECT_NAME } = publicRuntimeConfig;

let fetchWalletTimeout: NodeJS.Timeout | null = null;

function UseGatewayPanel({ Component, pageProps }: AppProps) {
  const { pathname } = useRouter();
  const { isDown } = useBreakpoints();

  const [hasInit, setHasInit] = useState<boolean>(false);
  const [user, setUser] = useState<UserDto | null>(null);
  const [wallet, setWallet] = useState<WalletDto>();
  const [walletPending, setWalletPending] = useState(false);
  const [walletRates, setWalletRates] = useState<RatesDto>();

  const technicalWorks = () => {
    const url = window.location.pathname + window.location.search;

    if (!isItTechnicalWorksPage(url)) {
      technicalWorksRedirect('/login');
    }
  };

  const fetchSystemStatus = async () => {
    await api
      .getSystemStatus()
      .then((status) => {
        const resut = checkPageDependencies(status, ['auth/jwt/login', 'users']);
        if (!resut) technicalWorks();
      })
      .catch(() => {
        technicalWorksRedirect('/login');
      });
  };

  const clearUser = () => {
    Sentry.setUser(null);
    setUser(null);
    setWallet(undefined);
  };

  const fetchUser = async () => {
    await api
      .getCurrnetUser()
      .then((res) => {
        Sentry.setUser({ id: res.id, email: res.email });
        setUser(res);
      })
      .catch((e) => {
        clearUser();
        throw e;
      });
  };

  const fetchWallet = async () => {
    setWalletPending(true);
    await api
      .getBalance()
      .then((res: object) => setWallet(res))
      .catch(() => null);
    setWalletPending(false);
  };

  const fetchWalletRates = async () => {
    await api.getWalletRates().then((res) => setWalletRates(res));
  };

  const init = async () => {
    await Promise.all([fetchSystemStatus(), api.init()]);
    await fetchUser().catch(console.warn);
    await Promise.all([fetchWallet(), fetchWalletRates()]).catch(console.warn);

    setHasInit(true);
  };

  useEffect(() => {
    init();

    return () => {
      clearTimeout(fetchWalletTimeout as NodeJS.Timeout);
    };
  }, []);

  useEffect(() => {
    clearTimeout(fetchWalletTimeout as NodeJS.Timeout);
    if (wallet && !isItTechnicalWorksPage(pathname)) {
      fetchWalletTimeout = setTimeout(fetchWallet, random(7, 12) * 1000);
    }
  }, [wallet]);

  return (
    <GatewayThemeProvider>
      <ErrorBoundary>
        <Head>
          <title>{PROJECT_NAME}</title>
          <meta name="description" content={`${PROJECT_NAME} | personal area`} />
          <script
            dangerouslySetInnerHTML={{
              __html: `
              !function(){'use strict';var e=['debug','destroy','do','help','identify','is','off','on','ready','render','reset','safe','set'];if(window.noticeable)console.warn('Noticeable SDK code snippet loaded more than once');else{var n=function(e){return function(){var n=Array.prototype.slice.call(arguments);return n.unshift(e),t.push(n),t}},t=window.noticeable=window.noticeable||[];!function(){for(var o=0;o<e.length;o++){var r=e[o];t[r]=n(r)}}(),function(){var e=document.createElement('script');e.async=!0,e.src='https://sdk.noticeable.io/l.js';var n=document.head;n.insertBefore(e,n.firstChild)}()}}();

                `,
            }}
          />

          {/* Google tag (gtag.js) - Google Analytics */}
          {GOOGLE_TAG && (
            <>
              <Partytown
                debug={process.env.NODE_ENV !== 'production'}
                forward={['dataLayer.push']}
                resolveUrl={partytownResolver}
              />
              <script
                type="text/partytown"
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG}`}
              />
              <script
                type="text/partytown"
                dangerouslySetInnerHTML={{
                  __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', '${GOOGLE_TAG}');
                `,
                }}
              />
            </>
          )}
        </Head>
        {hasInit ? (
          <NoticeableProvider>
            <AuthProvider value={{ user, refetch: fetchUser, setUser }}>
              <WalletProvider
                value={{
                  wallet,
                  walletPending,
                  walletRates,
                  setWallet,
                  fetchWallet,
                  setWalletRates,
                }}>
                <Component {...pageProps} />
                <Modals />
              </WalletProvider>
            </AuthProvider>
          </NoticeableProvider>
        ) : (
          <FullScreenSpiner />
        )}
        <ModalRoot />
        <ToastContainer
          delay={10000}
          position={isDown(Breakpoints.md) ? 'bottom-center' : 'bottom-left'}
        />
      </ErrorBoundary>
    </GatewayThemeProvider>
  );
}

UseGatewayPanel.getInitialProps = async function () {
  return {
    runtimeEnv: RUNTIME_ENV,
  };
};

export default appWithTranslation(UseGatewayPanel);
