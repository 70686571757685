import { SystemStatus, SystemStatusDto } from '@use-gateway/types';

export function checkPageDependencies(
  status: SystemStatus,
  dependencies: Array<keyof SystemStatusDto>
) {
  let result = !!status;

  if (status && dependencies.length) {
    for (const key of dependencies) {
      if (!status[key]) {
        result = false;
        break;
      }
    }
  }

  return result;
}
