import { useState } from 'react';
import { Stack } from '@bedrock-layout/stack';
import {
  Button,
  Modal,
  ModalCongratulationsImg,
  ModalProps,
  ModalText,
} from '@use-gateway/components';
import { IconCongratulations } from '@use-gateway/icons';
import { CreateCryptoRequestModal } from './create-crypto-request-modal';
import { CreateFiatRequestModal } from './create-fiat-request-modal';

enum CreateRequestSteps {
  select = 'select',
  crypto = 'crypto',
  fiat = 'fiat',
  success = 'success',
}

export type CreateRequestProps = Partial<ModalProps>;
export function CreateRequestModal({ onClose }: CreateRequestProps) {
  const [step, setStep] = useState(CreateRequestSteps.select);

  if (step === 'crypto') {
    return (
      <CreateCryptoRequestModal
        onClose={onClose}
        onNext={() => setStep(CreateRequestSteps.success)}
      />
    );
  }

  if (step === 'fiat') {
    return (
      <CreateFiatRequestModal
        onClose={onClose}
        onNext={() => setStep(CreateRequestSteps.success)}
      />
    );
  }

  if (step === 'success') {
    return (
      <Modal
        title="Successfully"
        backVisible={false}
        onClose={onClose}
        onNext={onClose}
        nextText="Close">
        <ModalCongratulationsImg>
          <IconCongratulations />
        </ModalCongratulationsImg>
        <ModalText>
          <p>Request successfully created.</p>
          <p>Thank you for being with us!</p>
        </ModalText>
      </Modal>
    );
  }

  return (
    <Modal title="Create new request" onClose={onClose}>
      <Stack gutter={'lg'}>
        <Button onClick={() => setStep(CreateRequestSteps.crypto)}>Crypto request</Button>
        <Button onClick={() => setStep(CreateRequestSteps.fiat)}>Fiat request</Button>
      </Stack>
    </Modal>
  );
}
