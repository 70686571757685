import { useEffect, useMemo, useState } from 'react';
import { Center } from '@bedrock-layout/center';
import { Column, Columns } from '@bedrock-layout/columns';
import { Stack } from '@bedrock-layout/stack';
import { api, ResponseError } from '@use-gateway/api';
import {
  Bage,
  DoublePrice,
  ErrorMessage,
  Message,
  Modal,
  ModalProps,
  Spiner,
} from '@use-gateway/components';
import { IconAlertTriangle } from '@use-gateway/icons';
import { styled } from '@use-gateway/theme';
import {
  DepositDto,
  DepositsEvents,
  InvoicesEvents,
  PaymentResponseDto,
  PaymentsEvents,
  PricingItem,
} from '@use-gateway/types';
import { allTransactionsValue, price, useEventBus } from '@use-gateway/utils';

// ~~ Styles

const StatusSign = styled.div`
  display: flex;
  justify-content: center;
  font-size: 48px;
  color: ${({ theme }) => theme.colors.danger[100]};
`;

const ResolveColumn = styled(Column)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:nth-child(2n) {
    justify-content: flex-end;
  }
`;

interface DifferenceColumnProps {
  negative?: boolean;
}
const DifferenceColumn = styled(ResolveColumn)<DifferenceColumnProps>(({ theme, negative }) => ({
  fontWeight: 500,
  color: negative ? theme.colors.danger[100] : theme.colors.success[100],
  '&:before': {
    content: negative ? '"- "' : '"+ "',
  },
}));

// ~~ Main Component

export interface ResolveModalProps extends Partial<ModalProps> {
  id: string;
  type: 'payment' | 'invoice' | 'deposit';
}
export function ResolveModal({ id, type, onClose }: ResolveModalProps) {
  const { emit } = useEventBus();
  const [item, setItem] = useState<PaymentResponseDto | DepositDto | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [errorResolve, setErrorResolve] = useState<ResponseError | null>(null);
  const [negative, setNegative] = useState(false);

  const fullStatus = useMemo(
    () => (item ? Array.from(item.timeline).pop()?.status ?? 'Unresolved' : null),
    [item]
  );

  const combinedValue = useMemo(() => {
    if (!item) return null;
    return allTransactionsValue(item.transactions);
  }, [item]);

  const difference = useMemo(() => {
    if (!item || !combinedValue || !(item as PaymentResponseDto).pricing) return null;

    const amount =
      combinedValue.local.amount -
      ((item as PaymentResponseDto).pricing.local as PricingItem).amount;
    setNegative(amount < 0);

    return {
      currency: (item as PaymentResponseDto).pricing.local?.currency,
      amount: Math.abs(amount),
    };
  }, [item, combinedValue]);

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleResolve = async () => {
    try {
      if (type === 'payment') {
        await api
          .resolvePayment(id)
          .then(() => {
            emit(PaymentsEvents.paymentWasResolved);
            handleClose();
            setErrorResolve(null);
          })
          .catch(setErrorResolve);
      } else if (type === 'invoice') {
        await api
          .resolveInvoice(id)
          .then(() => {
            emit(InvoicesEvents.invoiceWasResolved);
            handleClose();
            setErrorResolve(null);
          })
          .catch(setErrorResolve);
      } else {
        await api
          .resolveDeposit(id)
          .then(() => {
            emit(DepositsEvents.depositWasResolved);
            handleClose();
            setErrorResolve(null);
          })
          .catch(setErrorResolve);
      }
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    if (type === 'payment') {
      api.getPayment(id).then(setItem).catch(setError);
    } else if (type === 'invoice') {
      api.getInvoice(id).then(setItem).catch(setError);
    } else {
      api.getDeposit(id).then(setItem).catch(setError);
    }
  }, []);

  return (
    <Modal
      title={'Resolve'}
      onClose={handleClose}
      backText={!error ? 'Cancel' : 'Close'}
      nextText={'Resolve'}
      onBack={handleClose}
      onNext={!error ? handleResolve : undefined}>
      {!item ? (
        <Center centerChildren>
          <Spiner />
        </Center>
      ) : error ? (
        <Message variant={'error'}>{error.message}</Message>
      ) : (
        <>
          <StatusSign>
            <IconAlertTriangle />
          </StatusSign>
          <Center centerText>
            <h2>{fullStatus}</h2>
          </Center>

          <Stack gutter={'xl'}>
            <p>There is a problem with the transaction!</p>
            {/* <p>
              Your customer has made more than one transactions. We&nbsp;recomend reaching out to
              resolve.
            </p> */}
            {['payment', 'invoice'].includes(type) && (
              <Columns columns={2}>
                <ResolveColumn>Amount requested</ResolveColumn>
                {(item as PaymentResponseDto).pricing && (
                  <ResolveColumn>
                    {price((item as PaymentResponseDto).pricing.local as PricingItem)}
                  </ResolveColumn>
                )}
              </Columns>
            )}
            <Columns columns={2}>
              <ResolveColumn>Transactions</ResolveColumn>
              <ResolveColumn>
                <Bage>
                  <b>{item.transactions.length}</b>
                </Bage>
              </ResolveColumn>
            </Columns>
            {difference && (
              <Columns columns={2}>
                <ResolveColumn>Difference from requested price</ResolveColumn>
                <DifferenceColumn negative={negative}>
                  {price(difference as PricingItem)}
                </DifferenceColumn>
              </Columns>
            )}
            <Columns columns={2}>
              <ResolveColumn>Total amount transacted</ResolveColumn>
              <ResolveColumn>
                {combinedValue && (
                  <DoublePrice primary={combinedValue.local} secondary={combinedValue.crypto} />
                )}
              </ResolveColumn>
            </Columns>
          </Stack>
          {errorResolve && (
            <>
              <br />
              <Message variant={'error'} onClose={() => setErrorResolve(null)}>
                <ErrorMessage error={errorResolve.message} />
              </Message>
            </>
          )}
        </>
      )}
    </Modal>
  );
}
