import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Stack } from '@bedrock-layout/stack';
import { yupResolver } from '@hookform/resolvers/yup';
import { api, ResponseError } from '@use-gateway/api';
import {
  ControlledInput,
  ControlledSelect,
  ErrorMessage,
  Message,
  Modal,
  ModalProps,
} from '@use-gateway/components';
import { WithdrawalsEvents } from '@use-gateway/types';
import { useAvailableCurrencies, useEventBus, useWallet } from '@use-gateway/utils';

const schema = yup
  .object()
  .shape({
    currency: yup.string().required(),
    address: yup.string().required(),
    amount: yup.number().required(),
    comment: yup.string(),
  })
  .required();

export type CreateCryptoRequestProps = Partial<ModalProps>;
export function CreateCryptoRequestModal({ onClose, onNext }: CreateCryptoRequestProps) {
  const { emit } = useEventBus();
  const { wallet } = useWallet();
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, getValues } = form;
  const [error, setError] = useState<ResponseError | null>(null);

  const currencies = useAvailableCurrencies(wallet);

  const createCryptoRequest = handleSubmit(async () => {
    await api
      .createWithdrawalCryptoRequest({
        address: getValues('address'),
        value: {
          crypto: {
            amount: getValues('amount'),
            currency: getValues('currency'),
          },
        },
        comment: getValues('comment'),
      })
      .then(() => {
        if (onNext) onNext();
        emit(WithdrawalsEvents.requestWasCreate);
        setError(null);
      })
      .catch(setError);
  });

  return (
    <Modal
      title={'Create new crypto request'}
      onClose={onClose}
      onNext={createCryptoRequest}
      nextText={'Create'}>
      <FormProvider {...form}>
        <form onSubmit={createCryptoRequest}>
          <Stack gutter={'lg'}>
            <ControlledSelect name="currency" label="Cryptocurrency" options={currencies} />
            <ControlledInput name={'address'} label={'Address'} />
            <ControlledInput name={'amount'} type="number" label={'Amount'} />
            <ControlledInput name={'comment'} label={'Comment'} />
            {error && (
              <Message variant={'error'} onClose={() => setError(null)}>
                <ErrorMessage error={error.message} />
              </Message>
            )}
          </Stack>
        </form>
      </FormProvider>
    </Modal>
  );
}
