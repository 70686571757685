import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { Stack } from '@bedrock-layout/stack';
import { yupResolver } from '@hookform/resolvers/yup';
import { api, ResponseError } from '@use-gateway/api';
import {
  Modal,
  ModalProps,
  ControlledInput,
  ControlledSelect,
  Input,
  ModalLoadingWrapper,
  Spiner,
  Message,
  ErrorMessage,
} from '@use-gateway/components';
import { FiatCurrency, PaymentsEvents } from '@use-gateway/types';
import { useAuth, useEventBus } from '@use-gateway/utils';

const validationSchema = yup
  .object()
  .shape({
    name: yup.string().required().max(255),
    description: yup.string().max(255),
    currency: yup.string().required(),
    amount: yup
      .number()
      .typeError('Enter a number')
      .required('Amount is a required field')
      .min(0.1)
      .max(9999999999),
    success: yup.string().url(),
    cancel: yup.string().url(),
  })
  .required();

export type CreatePaymentProps = Partial<ModalProps>;
export function CreatePaymentModal({ onClose }: CreatePaymentProps) {
  const { user } = useAuth();
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      description: '',
      currency: user?.local_currency || FiatCurrency.USD,
      amount: '',
      success: '',
      cancel: '',
    },
  });
  const { handleSubmit } = form;
  const { emit } = useEventBus();

  const [paymentUrl, setPaymentUrl] = useState<string | null>(null);
  const [pending, setPending] = useState(false);

  const [errorCreatePayment, setErrorCreatePayment] = useState<ResponseError | null>(null);
  const createPayment = handleSubmit(
    async ({ name, description, currency, amount, success, cancel }) => {
      setPending(true);

      await api
        .createPayment({
          name,
          description: description.trim() || null,
          pricing_type: 'fixed_price',
          local_price: {
            currency,
            amount: parseFloat(amount),
          },
          metadata: {
            'Сreated using the Panel': true,
          },
          redirect_url: success.trim() || null,
          cancel_url: cancel.trim() || null,
        })
        .then((payment) => {
          emit(PaymentsEvents.paymentWasCreated, payment);
          setPaymentUrl(payment.hosted_url);
          setErrorCreatePayment(null);
        })
        .catch(setErrorCreatePayment);

      setPending(false);
    }
  );

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleNext = () => {
    if (!paymentUrl) createPayment();
    else handleClose();
  };

  return (
    <Modal
      title={!paymentUrl ? 'Create new payment' : 'Copy payment URL'}
      onClose={onClose}
      backVisible={!paymentUrl}
      onNext={handleNext}
      nextText={!paymentUrl ? 'Create' : 'Close'}>
      {pending && (
        <ModalLoadingWrapper>
          <Spiner />
        </ModalLoadingWrapper>
      )}
      {!paymentUrl ? (
        <FormProvider {...form}>
          <form onSubmit={createPayment}>
            <Stack gutter={'lg'}>
              <ControlledInput name={'name'} label={'Payment name'} />
              <ControlledInput name={'description'} label={'Payment description'} />
              <div />
              <ControlledSelect
                name="currency"
                label={'Fiat currency'}
                defautValue={user?.local_currency}
                options={FiatCurrency}
              />
              <ControlledInput name={'amount'} type="number" label={'Fiat currency amount'} />
              <div />
              <ControlledInput name={'success'} label={'Success url'} mask={'https://+'} />
              <ControlledInput name={'cancel'} label={'Cancel url'} mask={'https://+'} />
            </Stack>
          </form>
        </FormProvider>
      ) : (
        <Stack gutter={'lg'}>
          <Input
            label={'Payment URL'}
            defautValue={paymentUrl}
            copyValue
            readonly
            externalLink={paymentUrl}
          />
        </Stack>
      )}
      {errorCreatePayment && (
        <>
          <br />
          <Message variant={'error'} onClose={() => setErrorCreatePayment(null)}>
            <ErrorMessage error={errorCreatePayment.message} />
          </Message>
        </>
      )}
    </Modal>
  );
}
