import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { api, ResponseError } from '@use-gateway/api';
import {
  Message,
  Modal,
  ModalLoadingWrapper,
  ModalProps,
  Spiner,
  Toggle,
} from '@use-gateway/components';
import { CryptoCurrency, TestCoins } from '@use-gateway/types';
import { disablingCryptocurrencies, enablingCryptocurrencies, useAuth } from '@use-gateway/utils';

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

const ItemDescription = styled.div`
  max-width: 280px;
  margin-right: 32px;

  label {
    display: block;
    margin-bottom: 4px;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.5;
  }

  p {
    margin: 0;
    color: #848893;
  }
`;

const SectionToggle = styled.div`
  display: flex;
  min-width: 36px;
`;

interface PaymentMethodsModalProps extends Partial<ModalProps> {
  availableCryptocurrencies: Array<CryptoCurrency | TestCoins>;
  includedCryptocurrencies: Array<CryptoCurrency | TestCoins>;
  listCryptocurrencies: Array<CryptoCurrency | TestCoins>;
  onResult: (value: Array<CryptoCurrency | TestCoins>) => void;
  defaultEnable?: boolean;
}

export function PaymentMethodsModal({
  availableCryptocurrencies,
  includedCryptocurrencies,
  listCryptocurrencies,
  onResult,
  onClose,
  defaultEnable,
}: PaymentMethodsModalProps) {
  const { t } = useTranslation();
  const { user, refetch } = useAuth();

  const [isPending, setIsPending] = useState(false);
  const [enable, setEnable] = useState(defaultEnable ?? true);
  const [error, setError] = useState<ResponseError | null>(null);

  const handleBtn = async () => {
    setIsPending(true);
    const resultObj = enable
      ? enablingCryptocurrencies({ listCryptocurrencies })
      : disablingCryptocurrencies({
          availableCryptocurrencies,
          includedCryptocurrencies,
          listCryptocurrencies,
        });

    if (user) {
      await api
        .patchCurrnetUser({ cryptocurrencies: { ...user.cryptocurrencies, ...resultObj } })
        .then((res) => {
          const { cryptocurrencies: resCryptocurrencies } = res;

          const arrSelected: Array<CryptoCurrency | TestCoins> = [];

          let key: CryptoCurrency | TestCoins;
          for (key in resCryptocurrencies) {
            if (resCryptocurrencies[key]) {
              arrSelected.push(key);
            }
          }

          onResult(arrSelected);
          if (onClose) onClose();
        })
        .catch(setError);
    }

    await refetch();
    setIsPending(false);
  };

  return (
    <Modal
      title={'Edit'}
      onClose={onClose}
      nextText={`Apply for ${listCryptocurrencies.length} methods`}
      onNext={handleBtn}>
      {isPending && (
        <ModalLoadingWrapper>
          <Spiner />
        </ModalLoadingWrapper>
      )}
      <Item>
        <ItemDescription>
          <label>Enable method</label>
          <p>You can adjust the display of methods in the table</p>
        </ItemDescription>
        <SectionToggle>
          <Toggle defaultValue={enable} onChange={setEnable} />
        </SectionToggle>
      </Item>
      {!enable && listCryptocurrencies.find((item) => item === CryptoCurrency.BNB) && (
        <ItemDescription>
          <p>All BEP tokens will be disabled</p>
        </ItemDescription>
      )}
      {!enable && listCryptocurrencies.find((item) => item === CryptoCurrency.ETH) && (
        <ItemDescription>
          <p>All ERC tokens will be disabled</p>
        </ItemDescription>
      )}
      {!enable && listCryptocurrencies.find((item) => item === CryptoCurrency.TRX) && (
        <ItemDescription>
          <p>All TRC tokens will be disabled</p>
        </ItemDescription>
      )}
      {error && (
        <Message variant={'error'} onClose={() => setError(null)}>
          <span
            dangerouslySetInnerHTML={{
              __html: t(
                typeof error.data?.detail === 'object' ? error.data.detail[0].msg : error.message,
                { ns: 'errors' }
              ),
            }}
          />
        </Message>
      )}
    </Modal>
  );
}
