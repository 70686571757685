import { useEffect, useState } from 'react';
import { toast } from 'react-toast';
import { Stack } from '@bedrock-layout/stack';
import { api } from '@use-gateway/api';
import {
  Button,
  Message,
  Modal,
  ModalLoadingWrapper,
  ModalProps,
  Spiner,
} from '@use-gateway/components';

export type WebhookTestProps = Partial<ModalProps>;
export function WebhookTestModal({ onClose }: WebhookTestProps) {
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [availableEvents, setAvailableEvents] = useState<Array<string>>([]);

  const init = async () => {
    setPending(true);
    await api.getWebhookEvents().then(setAvailableEvents).catch(setError);
    setPending(false);
  };

  const test = (event: string) => {
    api
      .testWebhook(event)
      .then(() => {
        toast.info('Event was emitted!');
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal title={'Emit test webhook event'} onClose={onClose}>
      {pending && (
        <ModalLoadingWrapper>
          <Spiner />
        </ModalLoadingWrapper>
      )}
      <Stack gutter={'lg'}>
        {error && <Message variant={'error'}>{error.message}</Message>}
        {availableEvents.map((event) => (
          <Button key={event} onClick={() => test(event)}>
            {event}
          </Button>
        ))}
      </Stack>
    </Modal>
  );
}
