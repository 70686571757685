import { useCallback, useEffect, useState } from 'react';
import { api } from '@use-gateway/api';
import {
  MetaDetail,
  Modal,
  ModalLoadingWrapper,
  ModalProps,
  Scroll,
  Spiner,
} from '@use-gateway/components';
import { DepositDto, IncomingTransactionDto, PaymentResponseDto } from '@use-gateway/types';
import { price } from '@use-gateway/utils';

export interface MetaDataModalProps {
  transaction: IncomingTransactionDto;
  onClose: ModalProps['onClose'];
}

export function MetaDataModal({ transaction, onClose }: MetaDataModalProps) {
  const [item, setItem] = useState<DepositDto | PaymentResponseDto>();

  const openLink = useCallback(() => {
    if (transaction.transaction_link) window.open(transaction.transaction_link, '_blank');
  }, [transaction.transaction_link]);

  useEffect(() => {
    if (transaction.payment) {
      api.getPayment(transaction.payment.code).then((payment) => {
        setItem(payment);
      });
    }
    if (transaction.deposit) {
      api.getDeposit(transaction.deposit.id).then((deposit) => {
        setItem(deposit);
      });
    }
    if (transaction.invoice) {
      api.getInvoice(transaction.invoice.code).then((invoice) => {
        setItem(invoice);
      });
    }
  }, [transaction]);

  return (
    <Modal
      onClose={onClose}
      title={'Transaction Details'}
      nextText={'View transaction'}
      onBack={onClose}
      onNext={transaction.transaction_link ? openLink : undefined}>
      {item ? (
        <>
          <MetaDetail variant={'vertical'} label="Name" value={item.name} copy />
          {item.description && (
            <MetaDetail variant={'vertical'} label="Description" value={item.description} />
          )}
          {transaction.txid && (
            <MetaDetail variant={'vertical'} label="TxID" value={transaction.txid} copy />
          )}
          <MetaDetail variant={'vertical'} label="Shared link" value={item.hosted_url} copy />
          <hr />
          <Scroll>
            <MetaDetail label="Amount" value={price(transaction.value.local)} />
            {item.metadata &&
              Object.keys(item.metadata).map((key) => {
                return <MetaDetail key={key} label={key} value={item.metadata[key]} />;
              })}
          </Scroll>
        </>
      ) : (
        <ModalLoadingWrapper>
          <Spiner />
        </ModalLoadingWrapper>
      )}
    </Modal>
  );
}
